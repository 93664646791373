<template>
    <div>
        <b-modal
            no-close-on-backdrop
            no-close-on-esc
            hide-header-close
            v-model="directoryModalShow"
            id="addDirectory"
            ref="addDirectory"
            :title="modalTitle()"
            @close="$emit('clearActionOnly')"
            @cancel="$emit('clearActionOnly')"
            @ok="addDirectory"
        >
            <b-form-group
                :class="{ row, error: v$.newDirectory.name.$errors.length }"
                :label="$t('forms.name')"
                label-for="newDirectoryName"
                label-cols-md="4"
                content-cols-lg="8"
            >
                <b-form-input
                    v-model="newDirectory.name"
                    id="newDirectoryName"
                />
                <div
                    class="input-errors"
                    v-for="error of v$.newDirectory.name.$errors"
                    :key="error.$uid"
                >
                    <small class="text-danger">{{ error.$message }}</small>
                </div>
            </b-form-group>
            <b-form-group
                class="row"
                :label="$t('forms.position')"
                label-for="url"
                label-cols-md="4"
                content-cols-lg="8"
            >
                <b-form-input
                    type="number"
                    v-model="newDirectory.position"
                    id="url"
                />
            </b-form-group>
            <b-form-group
                class="row"
                :label="$t('forms.roles')"
                label-for="h-roles"
                label-cols-md="4"
                content-cols-lg="8"
            >
                <v-select
                  v-if="roles.length > 0"
                    id="h-roles"
                    v-model="newDirectory.roles"
                    :options="roles"
                    taggable
                    multiple
                    push-tags
                />
            </b-form-group>
        </b-modal>
        <b-modal
            no-close-on-backdrop
            no-close-on-esc
            hide-header-close
            v-model="documentModalShow"
            id="addDocument"
            ref="addDocument"
            :title="modalTitle()"
            @close="$emit('clearActionOnly')"
            @cancel="$emit('clearActionOnly')"
            @ok="addDocument"
        >
            <b-form-group
                :class="{ row, error: v$.newDirectory.name.$errors.length }"
                :label="$t('forms.name')"
                label-for="newDirectoryName"
                label-cols-md="4"
                content-cols-lg="8"
            >
                <b-form-input
                    v-model="newDirectory.name"
                    id="newDirectoryName"
                />
                <div
                    class="input-errors"
                    v-for="error of v$.newDirectory.name.$errors"
                    :key="error.$uid"
                >
                    <small class="text-danger">{{ error.$message }}</small>
                </div>
            </b-form-group>
            <b-form-group
                class="row"
                :label="$t('forms.url')"
                label-for="url"
                label-cols-md="4"
                content-cols-lg="8"
            >
                <b-form-input
                    v-model="newDirectory.url"
                    id="url"
                />
            </b-form-group>
            <b-form-group
                class="row"
                :label="$t('forms.position')"
                label-for="url"
                label-cols-md="4"
                content-cols-lg="8"
            >
                <b-form-input
                    type="number"
                    v-model="newDirectory.position"
                    id="url"
                />
            </b-form-group>
            <b-form-group
                v-if="action === 'addDocument'"
                class="row"
                :label="$t('forms.importantUpdate')"
                label-for="importantUpdate"
                label-cols-md="4"
                content-cols-lg="8"
            >
                <b-form-checkbox
                    v-model="newDirectory.importantUpdate"
                    id="importantUpdate"
                    switch
                />
            </b-form-group>
            <b-form-group
                v-if="action === 'addDocument'"
                :class="{row}"
                :label="$t('forms.branches')"
                label-for="h-branches"
                label-cols-md="4"
                content-cols-lg="8"
            >
                <v-select
                    id="h-branches"
                    v-model="newDirectory.branches"
                    :options="branches"
                    :reduce="branch => branch.id"
                    taggable
                    multiple
                    push-tags
                />
            </b-form-group>
            <b-form-group
                v-if="action === 'addDocument'"
                :class="{row}"
                :label="$t('forms.stations')"
                label-for="h-stations"
                label-cols-md="4"
                content-cols-lg="8"
            >
                <v-select
                    id="h-stations"
                    v-model="newDirectory.stations"
                    :options="stations"
                    :reduce="station => station.id"
                    taggable
                    multiple
                    push-tags
                />
            </b-form-group>
            <b-form-group
                v-if="action === 'addDocument'"
                :class="{row}"
                :label="$t('forms.departments')"
                label-for="h-departments"
                label-cols-md="4"
                content-cols-lg="8"
            >
                <v-select
                    id="h-departments"
                    v-model="newDirectory.departments"
                    :options="departments"
                    :reduce="department => department.id"
                    taggable
                    multiple
                    push-tags
                />
            </b-form-group>
        </b-modal>
        <b-list-group>
            <b-list-group-item
                class="
            d-flex
            justify-content-between
            align-items-center
            list-group-item list-group-item-action
          "
                v-if="directory"
            >
                <a v-if="customer" @click="goUp" href="javascript:void(0)">..</a><router-link v-else :to="parentUrl">..</router-link>
            </b-list-group-item>
            <b-list-group-item
                class="
            d-flex
            justify-content-between
            align-items-center
            list-group-item list-group-item-action
          "
                v-for="(directory, index) in directories"
                :key="index"
            >
                <a v-if="customer" @click="$emit('updateDirId', directory.id)" href="javascript:void(0)"><BootstrapIcon class="me-2" icon="folder" size="2x"/>{{ directory.name }}</a>
                <router-link v-else :to="'/directory/' + directory.id"><BootstrapIcon class="me-2" icon="folder" size="2x"/>{{ directory.name }}</router-link>
                <div class="just-buttons">
                    <a @click="editDirectory(directory.id)" v-if="$helper.userCapability('update',$route)"
                       href="javascript:void(0);" class="custom-btn edit-btn">
                        <BootstrapIcon icon="pencil" size="1x"/>
                    </a>
                    <delete-btn @pressDelete="deleteDirectory(directory['@id'])"/>
                </div>
            </b-list-group-item>
            <b-list-group-item
                class="
            d-flex
            justify-content-between
            align-items-center
            list-group-item list-group-item-action
          "
                v-for="(document, index) in documents"
                :key="index"
            >

                <a :href="document.url" target="_blank">
                    <BootstrapIcon v-if="document.importantUpdate" class="me-2 red-icon" icon="file-earmark-text"
                                   size="2x"/><BootstrapIcon v-if="!document.importantUpdate" class="me-2" icon="file-earmark-text" size="2x"/>{{ document.name }}</a>
                <div class="just-buttons">
                    <a @click="editDocument(document.id)" v-if="$helper.userCapability('update',$route)"
                       href="javascript:void(0);" class="custom-btn edit-btn pull-right">
                        <BootstrapIcon icon="pencil" size="1x"/>
                    </a>
                    <delete-btn @pressDelete="deleteDocument(document['@id'])"/>
                </div>
            </b-list-group-item>
        </b-list-group>
    </div>
</template>

<script>
import DeleteBtn from "@/components/Buttons/DeleteBtn.vue";
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import vSelect from "vue-select";
import ResourceUtils from "@/mixins/ResourceUtils";
import ListUtils from "@/mixins/ListUtils";

export default {
    components: {
        DeleteBtn,
        vSelect
    },
    mixins: [ResourceUtils, ListUtils],
    props: {
        directory: {Object, required: true, default: null},
        action: {type: String, required: true, default: null},
        customer: {Object, default: null},
        directories: {
            type: Array, default() {
            }
        },
        documents: {
            type: Array, default() {
            }
        },
    },
    setup: () => ({v$: useVuelidate()}),
    validations() {
        return {
            newDirectory: {
                name: {required},
            },
        };
    },
    created() {
        this.setParentUrl()
        if (this.roles.length === 0) {
            this.getResourceList(this.$Roles, this.roles)
        }
    },
    watch: {
        directory() {
            this.setParentUrl()
        },
        action() {
            this.showModal()
        }
    },
    data() {
        return {
            parentUrl: '',
            directoryModalShow: false,
            documentModalShow: false,
            editId: null,
            newDirectory: {},
            branches: [],
            departments: [],
            stations: [],
            roles: []

        }
    },

    methods: {
        deleteDirectory(url) {
            this.$emit("deleteDirectory", url);
        },
        deleteDocument(url) {
            this.$emit("deleteDocument", url);
        },
        editDocument(id) {
            this.editId = id;
            this.loadDocument(id)
            this.$emit("editDocument");
        },
        editDirectory(id) {
            this.editId = id;
            this.loadDirectory(id)
            this.$emit("editDirectory");
        },
        loadDirectory(id) {
            this.$Directories.getResource({id}).then((response) => {
                this.newDirectory = response.data;
                let roles = response.data.roles;
                let names = [];
                this.roles.forEach(function (item) {
                    roles.forEach(function (role) {
                        if (item.id === role) {
                            names.push({
                                label: item.label,
                                id: item.id
                            })
                        }
                    });
                });

                this.newDirectory.roles = names
            });
        },
        loadDocument(id) {
            this.$Documents.getResource({id}).then((response) => {
                this.newDirectory = response.data;
            });
        },
        setParentUrl() {
            this.parentUrl = '/documents/'
            if (this.directory && this.directory.parent) {
                this.parentUrl = '/directory/' + this.directory.parent.id
            }
        },
        goUp() {
            if (this.directory && this.directory.parent) {
                this.$emit('updateDirId', this.directory.parent.id)
            } else {
                this.$emit('updateDirId', null)
            }
        },
        modalTitle() {
            if (this.action === 'addDirectory') {
                return 'Add Directory'
            }
            if (this.action === 'addDocument') {
                return 'Add Document'
            }
            if (this.action === 'editDirectory') {
                return 'Edit Directory'
            }
            if (this.action === 'editDocument') {
                return 'Edit Document'
            }
        },
        showModal() {
            if (this.action === 'addDirectory' || this.action === 'addDocument') {
                this.newDirectory = {}
            }
            if (this.action === 'addDirectory') {
                this.newDirectory = {}
                if (this.directory) {
                    let id = this.directory.id
                    this.$Directories.getResource({id}).then((response) => {
                        let roles = response.data.roles;
                        let names = [];
                        this.roles.forEach(function (item) {
                            roles.forEach(function (role) {
                                if (item.id === role) {
                                    names.push({
                                        label: item.label,
                                        id: item.id
                                    })
                                }
                            });
                        });
                        this.newDirectory.roles = names
                    });
                }
            }
            if (this.action === 'addDirectory' || this.action === 'editDirectory') {
                this.directoryModalShow = true
                
            }
            if (this.action === 'addDocument' || this.action === 'editDocument') {
                this.documentModalShow = true
                if (this.action === 'addDocument') {
                    this.getResourceList(this.$Branches, this.branches)
                    this.getResourceList(this.$Stations, this.stations)
                    this.getResourceList(this.$Departments, this.departments)
                }
            }
        },
        async addDirectory() {
            const isValid = await this.v$.$validate();
            if (isValid) {
                if (this.directory) {
                    this.newDirectory.parent = this.directory['@id']
                }
                if (this.customer) {
                    this.newDirectory.customer = this.customer['@id']
                }
                if (this.newDirectory.position) {
                    this.newDirectory.position = parseInt(this.newDirectory.position)
                }
                if (this.newDirectory.roles) {
                    this.newDirectory.roles = this.newDirectory.roles.map(function (role) {
                        return role.id
                    });
                }
                if (this.action === 'addDirectory') {
                    this.create(
                        this.$Directories,
                        this.newDirectory,
                        this.$t("messages.directoryCreated"),
                        null,
                        this.success,
                        this.error
                    );
                } else {
                    this.update(
                        this.$Directories,
                        this.newDirectory.id,
                        this.newDirectory,
                        this.$t("messages.directoryUpdated"),
                        null,
                        this.success,
                        this.error
                    );
                }

            }
        },
        async addDocument() {
            const isValid = await this.v$.$validate();
            if (isValid) {
                if (this.directory) {
                    this.newDirectory.directory = this.directory['@id']
                }
                if (!this.newDirectory.importantUpdate) {
                    this.newDirectory.importantUpdate = false
                }
                if (this.newDirectory.position) {
                    this.newDirectory.position = parseInt(this.newDirectory.position)
                }
                console.log(this.newDirectory.position);
                if (this.customer) {
                    this.newDirectory.customer = this.customer['@id']
                }
                if (this.action === 'addDocument') {
                    this.create(
                        this.$Documents,
                        this.newDirectory,
                        this.$t("messages.documentCreated"),
                        null,
                        this.success,
                        this.error
                    );
                } else {
                    delete this.newDirectory.importantUpdate
                    delete this.newDirectory.customer
                    delete this.newDirectory.branches
                    delete this.newDirectory.departments
                    delete this.newDirectory.stations
                    this.update(
                        this.$Documents,
                        this.newDirectory.id,
                        this.newDirectory,
                        this.$t("messages.documentUpdated"),
                        null,
                        this.success,
                        this.error
                    );
                }

            }
        },
        success() {
            this.$emit('clearAction')
            this.directoryModalShow = false
            this.documentModalShow = false
            this.newDirectory = {}
        },
        error() {
            this.$emit('clearAction')
        }
    },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>

